import React from 'react'
import Layout from '../components/layout'
import colorfulBalls from '../assets/illustrations/animated/colorful_particles.svg'
import starFish from '../assets/baby-pack/starfish.svg'

const feedbacks = () => {
  return (
    <Layout>
      <div id="colorful-wrapper" style={{ backgroundImage: 'url(' + colorfulBalls + ')' }}>
        <div className="w-full h-full max-w-3xl mx-auto text-center">
          <h2 className="my-6 text-4xl font-header">Témoignages des parents</h2>

          <section className="font-muli">
            <h3 className="text-3xl underlined">Julie et Harry ~ 17 mois </h3>
            <article className="relative p-8 mx-4 text-xl thought bg-lilas rounded-2xl">
              <p>
                Anna est une professionnelle qui fait un métier peu connu en France mais tellement utile !!!!
              </p>
              <p>
                Elle est toujours disponible, à l&apos;écoute et sait répondre à toutes les questions et
                situations, grâce à ses très solides connaissances. Grâce à elle, mon fils de 18 mois a pu
                apprendre à s&apos;endormir autrement qu&apos;au sein et dans son lit !
              </p>
              <p>
                Anna a su me conseiller et me rassurer à chaque étape. Je ne peux que la recommander 1000 fois
                et n&apos;hésitez pas à la contacter dès les premiers mois de vie de votre enfant 😊
              </p>
              <p>Merci Anna !</p>
            </article>

            <h3 className="mt-8 text-3xl underlined">Audrey et Julia ~ 20 mois</h3>
            <article className="relative p-8 mx-4 text-xl thought bg-lilas rounded-2xl">
              <p>
                &bull; Je me suis adressée à une consultante du sommeil afin que ma fille fasse des nuits
                paisibles, et dans son propre lit.{' '}
              </p>
              <p>
                &bull; Nous partons de loin avec Julia, 20 mois, qui dormait dans notre lit depuis ses 11 mois
                et qui ne supportait pas être dans son lit. Elle se réveillait souvent, bougeait beaucoup et
                les nuits étaient infernales pour nous aussi. L&apos;endormissement était long et se faisait
                au sein.
              </p>
              <p>
                &bull; Depuis que nous consultons Anna, la situation s&apos;est nettement améliorée, dès la
                2ème nuit, Julia dormait dans son lit et s&apos;endormait avec simplement des{' '}
                <span className="italic">shhhh</span> et une main sur le dos. Au bout de 8 jours elle nous a
                fait des nuits complètes 🥳 on en revenait pas! Grâce aux précieux conseils d&apos;Anna et à
                son planning régulier à suivre au quotidien, Julia a un rythme beaucoup plus cadré et ça a des
                répercussions positives sur son sommeil.
              </p>
              <p>
                &bull; On était clairement désespérés et épuisés avec mon mari, ça impactait notre quotidien
                et là on revit, les nuits sont calmes. De plus, Anna est une personne disponible, très à
                l&apos;écoute, et qui nous rassure sur le sentiment de culpabilité que nous pouvons ressentir
                en tant que parents et ça fait du bien de pouvoir échanger avec quelqu&apos;un d&apos;aussi
                bienveillant.
              </p>
              <p>
                La consulter fut la meilleure chose que nous nous pouvions faire pour l&apos;équilibre de
                notre famille, donc un grand MERCI à vous Anna.
              </p>
            </article>

            <h3 className="mt-8 text-3xl underlined">Célia et Charly ~ 2 et 3 ans</h3>
            <article className="relative p-8 mx-4 text-xl thought bg-lilas rounded-2xl">
              <p>Nous recommandons Anna !!!</p>
              <br />
              <p>
                Le coucher de notre enfant était toujours très compliqué depuis quelques mois avec des réveils
                nocturnes. Puis nous avons croisé le chemin de Anna et à partir de là tout a changé.
              </p>
              <p>
                La prise en charge est vraiment personnalisée, et la conseillère en sommeil toujours
                disponible pour nous guider, nous conseiller, et nous coacher. C’est un travail d’équipe, et
                je pense que la motivation est importante.
              </p>
              <p>
                Au bout de quelques nuits, tout s’est arrangé, coucher facile sans pleurs, plus de réveils
                nocturnes.
              </p>
              <p>Merci encore Anna, nous pouvons maintenant avoir des nuits paisibles.</p>
            </article>

            <h3 className="mt-8 text-3xl underlined">Laëtitia et Alyson ~ 8 mois</h3>
            <article className="relative p-8 mx-4 text-xl thought bg-lilas rounded-2xl">
              <p>
                On m&apos;a recommandé Anna car ma fille de 8 mois se réveillait environ 5-6 fois par nuit et
                parfois pendant 1h30, je vous laisse imaginer mon niveau de fatigue..
              </p>
              <p>
                Depuis 3 semaines que nous suivont le programme d&apos;Anna, les nuits sont complètes sans
                réveille durant la nuit. C&apos;est tellement plus agréable de voir son enfant reposé et nous
                aussi. On pensait bien faire car ce n&apos;est pas notre première mais non, il y avait
                quelques petites chose à ajuster.{' '}
              </p>
              <p>
                Je recommande fortement Anna à tous les parents qui ont des difficultés avec les nuits de
                leurs enfants, n&apos;attendez pas....
              </p>
            </article>

            <h3 className="mt-8 text-3xl underlined">Tiffanie et Sacha ~ 5 mois</h3>
            <article className="relative p-8 mx-4 text-xl thought bg-lilas rounded-2xl">
              <p>
                J’ai fais appel à Anna fin août pour m’aider avec notre fils de 5 mois qui ne s’endormait que
                dans mes bras et se réveillait au bout de 15 minutes jour et nuit.
              </p>
              <p>J’étais épuisée. Je n’arrivais pas à le poser la journée car il voulait que les bras.</p>
              <p>J’ai également une grande de 4 ans je ne pouvais pas m’en occuper.</p>
              <p>
                La pédiatre voulait faire hospitalisé notre fils afin que je me repose j’ai refusé ! J’ai donc
                écris à Anna !
              </p>
              <p>J’y croyais pas mais je me suis dis que c’était ma dernière chance !</p>
              <p>Et en une semaine tout était casi réglé !</p>
              <br />
              <p>
                Il dormait mieux, s’endormait ds mes bras mais au lieu de me réveiller 12 fois la nuit il me
                réveillait 2/3 fois ! Puis la deuxième semaine il a commencé à s’endormir seul ! Miracle !
              </p>

              <br />
              <p>Je n’y crois toujours pas.</p>
              <p>Notre fils dort donc très bien. Il fait de bonnes siestes maintenant.</p>
              <p>Il ne réclame plus les bras la journée. Je peux le poser dans son parc.</p>
              <p>Je revis ! Il y a 3 semaines en arrière j’aurai jamais cru en être là !</p>
              <p>Merci Anna ! Vous avez été merveilleuse.</p>
              <p>Vous m’avez aidé et encourager dans les moments de doutes ...</p>
              <p>Sans vous je crois que c’est moi qui serai hospitalisé pour dépression.</p>
              <p>Merci infiniment !</p>
              <p>Je vous la recommande à 500%.</p>
              <p>Merci merci ❤️</p>
            </article>

            <h3 className="mt-8 text-3xl underlined">Jeanne et Victor ~ 5 mois</h3>
            <article className="relative p-8 mx-4 text-xl thought bg-lilas rounded-2xl">
              <p>Bonjour 🙂 🌷</p>
              <p>Récit de l’ancien bébé koala très tenace 🌷</p>
              <p>
                Mon fils était un bébé koala qui ne dormait que sur nous , au sein ou bercé... sauf qu’un bébé
                grossit et je ne pouvais plus à 5 mois passer mes journées bloquées au lit avec lui , et mes
                épaules ne suivaient plus le rythme pour bercer... sans oublier les soirées totalement
                inexistantes avec mon mari et moi, ou nos amis lorsque nous sommes partie en vacances.
              </p>
              <p>
                Et bien sur nous avons rencontré la fameuse régression de sommeil des 4 mois où notre fils se
                réveillait toutes les heures !
              </p>
              <p>Nous étions épuisés. Je craquais je n’en pouvais plus de cette situation.</p>
              <p>
                Il fallait faire quelque chose...Cependant je ne me sentais pas du tout à l’aise avec
                certaines méthodes de «laisser pleurer ».
              </p>
              <p>Nous avons donc contacté Anna.</p>
              <p>Anna nous a sauvé !</p>
              <br />
              <p>
                Nous n’avions aucunes connaissances sur le sommeil des bébés , nous faisions les choses un peu
                selon notre fils qui en plus était allaité à la demande.. nous n’avions aucun rythme... et en
                fait nous n’avions plus de vie.. 🤦🏽‍♀️
              </p>
              <p>
                Anna nous a apporté toutes les connaissances nécessaires afin de comprendre ce qui n’allait
                pas, et comprendre en quoi les méthodes fonctionneraient. Avant d’agir il faut comprendre, et
                Anna est une prof excellente.
              </p>
              <p>
                Mon fils pleurait dès que je le posais dans son lit, ses siestes duraient 30 min et je devais
                systématiquement les prolonger soit en le berçant soit en le mettant au sein.
              </p>
              <p>Mignon les premières semaines .. épuisant au bout de 5 mois...</p>
              <p>Je ne pouvais espérer le poser dans son lit que le soir à 22h!</p>
              <p>
                Notre vie commençait donc à 22h... mais il fallait faire vite car de nombreux réveils
                nocturnes nous attendaient.
              </p>
              <p>Anna a été merveilleuse car :</p>
              <p>
                <img src={starFish} alt="étoile de mer" className="inline h-8 transform rotate-45" /> elle
                nous a toujours écouté
              </p>
              <p>
                <img src={starFish} alt="étoile de mer" className="inline h-8 transform rotate-45" /> elle a
                toujours trouvé des solutions à nos soucis
              </p>
              <p>
                <img src={starFish} alt="étoile de mer" className="inline h-8 transform rotate-45" /> elle a
                toujours réajusté les méthodes car notre fils était sacrément tenace (progrès puis
                régressions)
              </p>
              <p>
                <img src={starFish} alt="étoile de mer" className="inline h-8 transform rotate-45" /> elle ne
                nous a jamais abandonné car nous voulions utiliser une méthode douce et les méthodes douces
                peuvent être longues mais cela fonctionne et cela correspondait à ce que nous souhaitions mais
                SURTOUT cela correspondait à notre fils . Sans même le voir , avec nos nombreux échanges (Anna
                fait preuve d’une disponibilité , d’une empathie et patiente à toute épreuve ! Des qualités
                remarquables et essentielles pour le métier qu’elle exerce) elle a bien cerné notre fils et
                ses ajustements nous ont permis d’arriver à un résultat incroyable ! En plus nous avons du
                voyager et Anna a pris cela en compte et à encore une fois ajusté la façon dont nous devions
                procéder
              </p>
              <p>
                <img src={starFish} alt="étoile de mer" className="inline h-8 transform rotate-45" /> elle
                nous a soutenu encouragé et rassuré : merci pour tout ça Anna ! C’est si précieux pour une
                jeune maman épuisé et au fond du gouffre 🙈
              </p>
              <br />
              <p className="font-bold">🎉🎉🎉🎉 Le résultat 🎉🎉🎉🎉</p>
              <p>&bull; Mon fils aime dormir dans son berceau !</p>
              <p>&bull; Il fait des siestes de plus d’une heure</p>
              <p>
                &bull; Il s’endort seul (parfois si il a un peu de mal nous venons lui faire un bisou et un
                câlin pour le rassurer et il s’endort)
              </p>
              <p>&bull; Il a un rythme de siestes de mieux en mieux</p>
              <p>
                &bull; Il commence à faire ses nuits 🤩(en se réveillant la nuit il se rendort tout seul en
                gazouillant) car il est de plus en plus autonome grâce au travail réalisé avec Anna{' '}
              </p>
              <p>
                Je pensais ne jamais y arriver mais j’ai fais confiance à Anna et cela a fonctionné ! En
                douceur avec notre fils , mais le résultat est là !
              </p>
              <br />
              <p>
                🌺 Anna merci pour ton empathie, ta gentillesse, ta patiente, ta capacité à t’adapter et
                adapter les méthodes selon le caractère de notre bébé !
              </p>
              <p>Merci de nous avoir aidé à tenir sur la distance 🙏🏽</p>
              <p>
                Mon bébé était sacrément tenace , mais Anna ne nous a pas lâché, et nous avons suivi tous ses
                conseils.
              </p>
              <p>
                Si vous rencontrez des soucis de sommeil avec bébé ... n’hésitez pas ! Anna est formidable !
              </p>
              Bébé Victor, Renaud & Jeanne Merci ☀️💙
            </article>
          </section>

          <section className="font-muli">
            <h3 className="mt-8 text-3xl underlined">Stéphanie et Samuel ~ 10,5 mois</h3>
            <article className="relative p-8 mx-4 text-xl thought bg-lilas rounded-2xl">
              <p>
                J’ai eu la chance de tomber sur l’annonce d’Anna sur Facebook à un moment où mon conjoint et
                moi-même étions désespérés.
              </p>
              <br />

              <p>
                Notre petit garçon avait 10 mois à l’époque et n’avait fait seulement que 4 nuits complètes...
                Il s’endormait seul, sans problème, mais pouvait se réveiller jusqu’à 20 fois par nuit,
                parfois toutes les 15 minutes. Il se réveillait également très tôt.
              </p>
              <br />

              <p>
                En rencontrant Anna, j’ai tout de suite ressenti de la bienveillance, de la compréhension, à
                aucun moment je ne me suis sentie jugée dans mon rôle de maman. Et cela fait un bien fou ! On
                nous a tout reproché...d’être trop proche de notre fils, de ne pas l’être assez (car il dort
                dans sa chambre)...
              </p>
              <br />

              <p>
                A la suite de ce rdv, elle m’a envoyé un plan du sommeil adapté à notre fils. Cela n’a pas été
                facile, et parfois il y a eu des échecs, mais en deux semaines, notre fils faisait de
                meilleures siestes et dormait 11h de suite sans problème. Il a commencé à faire ses nuits !
                Parfois il y avait des petits réveils mais nous tenions bon pour éviter de reprendre les
                vieilles habitudes. Le plan a parfaitement fonctionné !
              </p>
              <br />

              <p>
                D’une patience sans faille et d’une infinie gentillesse, Anna a été présente à chaque moment.
                Dès que nous la sollicitions, la réponse ne tardait pas.
              </p>
              <br />

              <p>
                Je la recommande maintenant à mes connaissances. Si elle m’a aidé elle peut en aider beaucoup
                d’autres !
              </p>
              <br />

              <p>
                En plus d’avoir aidé notre enfant à dormir paisiblement, elle m’a aussi beaucoup aidé en
                m’écoutant et en prenant en compte mes sentiments. Il est rare que l’on prenne le temps
                d’écouter les mamans, on passe rapidement pour la « mauvaise mère ». Je n’ai jamais senti
                autre chose que de la bienveillance dans l’accompagnement d’Anna.
              </p>
              <br />

              <p>Encore mille mercis à elle.</p>
            </article>
          </section>

          <section className="font-muli">
            <h3 className="mt-8 text-3xl underlined">Raphaëlle et Ilyès ~ 3 mois</h3>
            <article className="relative p-8 mx-4 text-xl thought bg-lilas rounded-2xl">
              <p>
                Je recommande Anna sans hésitation, qui a su nous aiguiller vis à vis du sommeil de notre fils
                de quelques mois qui avait commencé à faire ses nuits vers 2 mois et vers 3-4 mois : des
                réveils nocturnes sont apparus !
              </p>
              <br />

              <p>
                Anna est une personne à l’écoute, fiable et compétente dans ce domaine et permet aux parents
                de garder confiance en eux.
              </p>
              <br />

              <p>
                En effet, grâce à ses précieux conseils, nous avons accompagné notre bébé pour qu’il arrive à
                s’endormir seul progressivement. Aujourd’hui, à 5 mois, il s’endort très bien seul et fait de
                belles nuits dès 19h30 sans réveils nocturnes (hors pathologies gastro, rhume qui embêtent
                évidemment nos petits bouts...)
              </p>
              <br />

              <p>Merci encore Anna, pour vos conseils et votre suivi !</p>
            </article>
          </section>

          <section className="font-muli">
            <h3 className="mt-8 text-3xl underlined">Sandra et Ilyan ~ 1 an</h3>
            <article className="relative p-8 mx-4 text-xl thought bg-lilas rounded-2xl">
              <p>
                Anna nous a beaucoup aidé pour les problèmes de sommeil de notre fils. En effet dès ses 8 mois
                il se réveillait de nombreuses fois la nuit, sans se rendormir. Il a fallu le prendre au lit
                avec nous...
              </p>
              <br />

              <p>Grâce à ses nombreux conseils, Bébé dort beaucoup mieux.</p>
              <p>Elle est très compétente, très à l&apos;écoute et elle est très disponible.</p>
              <p>Elle est rassurante dans ces situations anxiogènes.</p>
              <br />

              <p>Merci encore pour tout Anna.</p>
              <p>Je la recommande à 10 000 %</p>
            </article>
          </section>

          <section className="font-muli">
            <h3 className="mt-8 text-3xl underlined">Marie et Arsène ~ 1 an</h3>
            <article className="relative p-8 mx-4 text-xl thought bg-lilas rounded-2xl">
              <p>Je remercie Anna pour ses conseils et son aide, ô combien précieuse !</p>
              <br />

              <p>
                Elle nous a aidé avec notre fils qui faisait plusieurs réveils par nuit sans que nous
                puissions l&apos;aider à se calmer et à se rendormir.
                <br />
                Elle nous a aidé à lui donner un rythme, nous a suivi et aidé tous les soirs durant la mise en
                place de la méthode de sommeil pour notre enfant.
              </p>
              <p>
                Encore actuellement lors d&apos;une &ldquo;rechute&ldquo;, elle nous aide et nous remet sur le
                droit chemin pour permettre un bon sommeil à notre enfant !
              </p>
              <br />

              <p>Grâce à ses nombreux conseils, Bébé dort beaucoup mieux.</p>
              <p>Elle est très compétente, très à l&apos;écoute et est très disponible.</p>
              <p>Elle est rassurante dans ces situations anxiogènes.</p>
              <br />

              <p>Une personne en or que je recommande sans hésitation. Milles mercis, Anna !</p>
            </article>
          </section>

          <section className="font-muli">
            <h3 className="mt-8 text-3xl underlined">Marion et Lola ~ 13 mois</h3>
            <article className="relative p-8 mx-4 text-xl thought bg-lilas rounded-2xl">
              <p>
                Anna est très professionnelle, prend son temps pour trouver la solution adéquate, elle est au
                top ! Je recommande 🤗
              </p>
            </article>
          </section>

          <section className="font-muli">
            <h3 className="mt-8 text-3xl underlined">Samia et Anis ~ 17 mois</h3>
            <article className="relative p-8 mx-4 text-xl thought bg-lilas rounded-2xl">
              <p>
                Suite à une recommandation, j’ai pris contact avec Anna. Tout ce que je peux vous dire c’est
                que depuis avoir suivi son programme j’ai pu enfin revivre !!!
              </p>
              <br />
              <p>
                Car à un certain moment j’étais tellement désespérée car mon petit qui avait presque 18 mois
                se réveillait 4 à 5 fois par nuit pour réclamer le biberon sinon il ne se rendormait pas ...
              </p>
              <br />
              <p>
                Vraiment un grand grand merci à Anna 😍 Et à mon tour je la recommande vivement !! Elle est
                top !
              </p>
            </article>
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default feedbacks
